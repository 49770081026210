import request from '../plugins/axios.js'

// 打印公共接口

// 获取订单详情
export function getOrderInfo( params ) {
  return request(
    'post',
    '/PrintUpdate/GetSellerView',
    params
  )
}

// 获取平台全部快递接口
export function getExpressList( params ) {
    return request(
      'post',
      '/Template/getExpressTemp',
      params
    )
}

// 获取店铺打印模版接口
export function getTemplate( params ) {
    return request(
      'post',
      '/Template/getTemplate',
      params
    )
}

// 添加快递模板
export function addTemplate( params ) {
  return request(
    'post',
    '/Template/addShopTemp',
    params
  )
}

// 设置默认发货模板
export function setDefFahuoTemplate( params ) {
  return request(
    'post',
    '/Template/setInvoiceDef',
    params
  )
}

// 获取默认的发货单模板
export function getDefFahuoTemplate( params ) {
  return request(
    'post',
    '/Template/getInvoiceDef',
    params
  )
}

// 获取发货单发件人列表
export function getFahuoConsignerList ( ) {
  return request(
    'post',
    '/BasicSetting/getAddressInvoice',
  )
}

// 获取快递单发件人列表
export function getExpressConsignerList ( ) {
  return request(
    'post',
    '/BasicSetting/getAddressExpress',
  )
}

// 修改订单地址
export function updateOrderAddress ( params ) {
  return request(
    'post',
    '/PrintSearch/UpdateOrderArea',
    params
  )
}

// 获取电子面单信息
export function getExpressOrderInfo ( params ) {
  return request(
    'post',
    '/Waybill/get',
    params
  )
}

// 修改列表表头配置
export function setFieldData ( params ) {
  return request(
    'post',
    '/BasicSetting/setFieldData',
    params
  )
}

// 获取列表表头配置
export function getFieldData ( params ) {
  return request(
    'post',
    '/BasicSetting/getFieldData',
    params
  )
}