import { testToken } from "@/api/test.js";
export default function Token(){
  console.log("---test_get_token")
  return new Promise((resolve, reject) => {
    let token=window.sessionStorage.getItem("token");
    if(!token){
      testToken().then((res) => {
        var tokenStr = res.data.token;
        window.sessionStorage.setItem("token", tokenStr);
        resolve();
      });
    }else{
      resolve();
    }
  });
}