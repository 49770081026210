import request from '../plugins/axios.js'

// 批量打印接口

// 添加备注
export function addOrderRemark( params ) {
    return request(
      'post',
      '/PrintUpdate/OrderMemoAdd',
      params
    )
}

// 批量更新发货单状态
export function updateFahuoState( params ) {
  return request(
    'post',
    '/PrintRecord/add',
    params
  )
}

// 批量更新快递单状态
export function batchUpdateExpressState( params ) {
  return request(
    'post',
    '/PrintUpdate/BatchUpdateExpressState',
    params
  )
}

// 获取网点信息
export function WaybillSearch ( params ) {
  params["NO_SHOW_LOADING"] = true
  return request(
    'post',
    '/Waybill/search',
    params
  )
}

// 获取淘宝授权链接
export function TaobaoAuthURL ( params ) {
  return request(
    'post',
    '/TopAuth/getUrl',
    params
  )
}

//取消淘宝授权

export function cancelAuth ( params ) {
  return request(
    'post',
    '/TopAuth/cancelAuth',
    params
  )
}

// 更新挂起状态
export function updateHang ( params ) {
  return request(
    'post',
    '/PrintUpdate/updateHang',
    params
  )
}

// 模板排序
export function updateSortTemplate ( params ) {
  return request(
    'post',
    '/Template/updateSort',
    params
  )
}

// 设置默认网点
export function set_default_branch ( params ) {
  return request(
    'post',
    '/Waybill/default_branch',
    params
  )
}

// 设置默认网点
export function OpDeliverySendOrder ( params ) {
  return request(
    'post',
    '/PrintUpdate/OpDeliverySendOrder',
    params
  )
}


