import { getPrintRecordList,getDeliveryRecord,getRefundList } from '@/api/utilities.js'

const state = {
	loadInfo: [], //分页等信息
	print_list: [], //打印记录列表
	print_list_id: [], //打印记录列表
	delivergoods_list: [], //发货记录列表
	refund_list:[],//售后订单
	express_list:[],//售后订单
	progressBarPopup: false, 
}
const mutations = {
	// 打印记录
	PRINT_LIST: (state, params) => {
		state.print_list = params;
	},
	PRINT_LIST_ID: (state, params) => {
		state.print_list_id = params;
	},
	//发货记录
	DELIVERGOODS_LIST: (state, params) => {
		state.delivergoods_list = params;
	},
	REFUND_LIST: (state, params) => {
		state.refund_list = params;
	},
	SET_PROGRESS_BAR_POPUP:(state, data)=>{
		state.progressBarPopup = data
	},
}
const actions = {
	// 打印记录查询
	getPrintRecordList({ commit }, params) {
		return new Promise((resolve, reject) => {
			getPrintRecordList(params).then(res => {
				var data = res.data;
				for(let i in data.data){
					data.data[i].id=parseInt(i)+1
				  } 
				commit("PRINT_LIST", data.data);
				resolve(res);

			}).catch(error => {
				reject(error)
			})
		})
	},
	//发货记录查询
	getDeliveryRecord({ commit }, params) {
		return new Promise((resolve, reject) => {
			getDeliveryRecord(params).then(res => {
				var data = res.data;
				for(let i in data.data){
					data.data[i].id=parseInt(i)+1
				  } 
				commit("DELIVERGOODS_LIST", data.data);
				resolve(res);

			}).catch(error => {
				reject(error)
			})
		})
	},
	//售后订单
	getRefundList({ commit }, params) {
		return new Promise((resolve, reject) => {
			let query_state = true // 通过查询中状态控制轮询间隔
			query_fn()
			let query_timer = setInterval(query_fn,5000)
			// 定义查询
			async function query_fn (){
				if(query_state){
					query_state = false
					let res = await getRefundList(params)
					if(res.data.task_progress || res.data.task_progress == 0){
						query_state = true
						commit('SET_PROGRESS_BAR_POPUP', res.data.task_progress)
					}else{
						commit('SET_PROGRESS_BAR_POPUP', false)
						let data = res.data
						for(let i in data.data){
							data.data[i].id=parseInt(i)+1
							if(data.data[i].refund_status=='waitselleragree'){
								data.data[i].refund_status='等待卖家同意'
							}
							if(data.data[i].refund_status=='refundsuccess'){
								data.data[i].refund_status='退款成功'
							}
							if(data.data[i].refund_status=='refundclose'){
								data.data[i].refund_status='退款关闭'
							}
							if(data.data[i].refund_status=='waitbuyermodify'){
								data.data[i].refund_status='待买家修改'
							}
							if(data.data[i].refund_status=='waitbuyersend'){
								data.data[i].refund_status='等待买家退货'
							}
							if(data.data[i].refund_status=='waitsellerreceive'){
								data.data[i].refund_status='等待卖家确认收货'
							}
						}
						commit('REFUND_LIST', res.data.data)
						clearInterval(query_timer)
						resolve(res)
					}
				}
			}
		})
	},
}
export default {
	namespaced: true,
	state,
	mutations,
	actions
}