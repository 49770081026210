<template>
  <div id="app">
    <div class="app-container bg-color">
      <!-- 顶部导航 -->
      <topnavigation />
      <!-- 主体 -->
      <router-view />
      <!-- 右侧菜单 -->
      <!-- <right-tips/> -->
    </div>
    <appDialog @closePopup="()=>{this.dialogShow=false}" v-if="dialogShow"/>
  </div>
</template>

<script>
import appDialog from "@/components/appDialog";
import topnavigation from "@/components/TopNavigation";
import RightTips from "@/components/RightTips";
// lodop初始化
import {lodop_init} from '@/utils/lodopPrint.js'
import ks_print from "@/utils/ks_print";

export default {
  components: { 
    topnavigation, 
    RightTips,
    appDialog
  },
  name: "App",
  data() {
    return {
      dialogShow: false
    };
  },
  methods: {
  },
  mounted() {
    // 获取一些必要的数据
    this.$store.dispatch("setting/getConfig")
    this.$store.dispatch('print/get_express_template');
    // this.$store.dispatch('print/get_fahuo_template');
    this.$store.dispatch('print/get_express_list');
    this.$store.dispatch('print/get_fahuo_consigner_list');
    this.$store.dispatch('print/get_express_consigner_list');

    // 检测CLodop打印机组件
    window.onload = ()=>{
      lodop_init().then(res=>{
        window.component_installed_type = res
        if(!res){
          this.$message.error("提示: lodop打印组件无法链接");
          this.dialogShow = true
        }
      })
    }
    // 初始化快手打印机组件
    let douyin_params = new Object()
    douyin_params.onmessage= (event) => {
      console.log(event, "---onmessage")
      let response = JSON.parse(event.data);
      if (response.cmd == 'getPrinters') { // 监听打印机列表
        let print_list = response.printers.map(item=>{
          return item.name
        })
        console.log(print_list,'---快手打印机列表获取成功')
        window.ks_print_list = print_list // 别管用不用得上,先保存一份打印机列表
      }else if(response.cmd == 'print'){ // 监听打印事件
        console.log(response,'---print')
      }else if(response.cmd == 'getTaskStatus'){ // 监听打印事件
        console.log(response,'---getTaskStatus')
      }
    }
    douyin_params.onerror = (res)=>{
      this.dialogShow = true
      window.ks_print_error == "提示: 快手打印组件无法链接"
    }
    douyin_params.onclose = (res)=>{
      this.$message.error("提示: 快手打印组件无法链接");
      window.ks_print_error == "提示: 快手打印组件无法链接"
    }
    window.ks_print = new ks_print(douyin_params)
  }
};
</script>
<style lang="less" scoped>
.app-container {
  height: 100%;
  display: flex;
  flex-direction: column;
}
</style>
