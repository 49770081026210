import request from '../plugins/axios.js'

// 获取基础设置
export function getConfig() {
    return request(
        'post',
        '/BasicSetting/getConfig',
    )
}
// 新增、编辑快递单发件人信息
export function editAddressExpress(data) {
    return request(
        'post',
        '/BasicSetting/editAddressExpress',
        data,
    )
}
// 新增、编辑发货单发件人信息
export function editAddressInvoice(data) {
    return request(
        'post',
        '/BasicSetting/editAddressInvoice',
        data,
    )
}
// 快递单发件人列表
export function getAddressExpress() {
    return request(
        'post',
        '/BasicSetting/getAddressExpress',
    )
}
// 发货单发件人列表接口
export function  getAddressInvoice(){
    return request(
        'post',
        '/BasicSetting/getAddressInvoice',
    )
}

// 基础配置接口
export function editConfig(data) {
    return request(
        'post',
        '/BasicSetting/editConfig',
        data,
    )
}
// 获取授权链接
export function getAuthorUrl() {
  return request(
    'post',
    '/TopAuth/getUrl'
  )
}
// 获取当前店铺信息
export function getShopInfo() {
  return request(
    'post',
    '/Shop/getShopInfo'
  )
}