import request from '../plugins/axios.js'

// 单个打印接口

// 更新发货单状态
export function updateFahuoState( params ) {
  return request(
    'post',
    '/PrintUpdate/UpdateInvoiceState',
    params
  )
}

// 更新快递单状态
export function updateExpressState( params ) {
  return request(
    'post',
    '/PrintUpdate/UpdateExpressState',
    params
  )
}
// 数据解密
export function decryptBuyer( params){
  return request(
    'post',
    'PrintSearch/decryptBuyer',
    params
  )
}
// 修改标记
export function updateSign( params){
  return request(
    'post',
    '/PrintUpdate/updateSign',
    params
  )
}

// 修改备注
export function OrderMemoAdd( params){
  return request(
    'post',
    '/PrintUpdate/OrderMemoAdd',
    params
  )
}