// 公共过滤器文件
import Vue from 'vue'

// 时间过滤器
Vue.filter('dateFormat', function (originVal) {
    var y = originVal.slice(0,4);
    var m = originVal.slice(4,6);
    var d = originVal.slice(6,8);
  
    var hh = originVal.slice(8,10);
    var mm = originVal.slice(10,12);
    var ss = originVal.slice(12,14);

    return `${y}-${m}-${d} ${hh}:${mm}:${ss}`
  })

// 空字符串过滤器
Vue.filter('nullToNum', function (originString) {
  if (originString == ''){
    return 0;
  }
  else if (typeof(originString) === 'undefined'){
    return 0;
  }
  else {
    return originString;
  }
})
Vue.filter('nullToChinese', function (originString) {
  if (originString == ''){
    return '无';
  }
  else {
    return originString;
  }
})

// boolean过滤器
Vue.filter('stringToBool', function (originString) {
  var filterBool = true
  if (originString == '0'){
    filterBool = false
  }
  else if (originString == '1'){
    filterBool = true
  }
  else {
    filterBool = true
  }
  return filterBool
})

// 订单状态过滤器  	 	 	  
Vue.filter('orderStatusFormat', function (originString) {
  if (originString == 'waitbuyerpay'){
    return '等待买家付款'
  }
  else if (originString == 'waitsellersend'){
    return '等待卖家发货'
  }
  else if (originString == 'waitbuyerreceive'){
    return '等待买家确认收货'
  }
  else if (originString == 'success'){
    return '交易成功'
  }
  else if (originString == 'cancel'){
    return '交易关闭'
  }
  else if (originString == 'paid_but_not_fund'){
    return '已支付，未到账'
  }
  else if (originString == 'confirm_goods'){
    return '已收货'
  }
  else if (originString == 'waitsellerconfirm'){
    return '等待卖家确认订单'
  }
  else if (originString == 'waitbuyerconfirm'){
    return '等待买家确认订单'
  }
  else if (originString == 'confirm_goods_but_not_fund'){
    return '已收货，未到账'
  }
  else if (originString == 'confirm_goods_and_has_subsidy'){
    return '已收货，已贴现'
  }
  else if (originString == 'send_goods_but_not_fund'){
    return '已发货，未到账'
  }
  else if (originString == 'waitlogisticstakein'){
    return '等待物流揽件'
  }
  else if (originString == 'waitbuyersign'){
    return '等待买家签收'
  }
  else if (originString == 'signinsuccess'){
    return '买家已签收'
  }
  else if (originString == 'signinfailed'){
    return '签收失败'
  }
  else if (originString == 'waitselleract'){
    return '等待卖家操作'
  }
  else if (originString == 'waitbuyerconfirmaction'){
    return '等待买家确认操作'
  }
  else if (originString == 'waitsellerpush'){
    return '等待卖家推进'
  }
  else {
    return '未知状态'
  }
  
})

// 快递过滤器
Vue.filter('expressFormat', function (originString) {
  if (originString == 0){
    return '圆通'
  }
  else if (originString == 1){
    return '申通'
  }
  else if (originString == 2){
    return '中通'
  }
  else if (originString == 3){
    return '顺丰'
  }
  else if (originString == 4){
    return '天天'
  }
  else if (originString == 5){
    return '百世快递'
  }
  else if (originString == 6){
    return '邮政'
  }
  else if (originString == 7){
    return '德邦快递'
  }
  else if (originString == 8){
    return '韵达'
  }
  else {
    return '未知快递'
  }
})