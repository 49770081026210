import {
	getExpressList,
	getTemplate,
	addTemplate,
	setDefFahuoTemplate,
	getDefFahuoTemplate,
	getFahuoConsignerList,
	getExpressConsignerList,
	updateOrderAddress,
	getExpressOrderInfo,
	getFieldData,
	setFieldData,

} from '@/api/print.js'

// 打印Module

const state = {
	// 打印机列表
	printerlist: {},
	// 快递列表
	expressList: {},
	// 快递模板列表
	expressTemplateList: {},
	// 要添加的快递id
	addExpressId: '',
	// 发货单模板列表
	fahuoTemplateList: {},
	// 选择的发货单id
	selectFahuoId: '22',
	// 默认的发货单模板
	defFahuoTemplate: {},
	// 发货单发货人列表
	fahuoConsignerList: [],
	// 快递单发货人列表
	expressConsignerList: [],
	
	tableHeader: [], // 列表的表头
	tableHeader2: [], // 列表的表头
}

const mutations = {
	// 修改打印机列表
	SET_PRINTER_LIST: (state, data) => {
		state.printerlist = data
	},
	// 修改快递列表
	SET_EXPRESS_LIST: (state, data) => {
		state.expressList = data
	},
	// 修改快递模板列表
	SET_EXPRESS_TEMPLATE_LIST: (state, data) => {
		state.expressTemplateList = data
	},
	// 修改要添加的快递id
	SET_ADD_EXPRESS_ID: (state, data) => {
		state.addExpressId = data
	},
	// 修改发货模板列表
	SET_FAHUO_TEMPLATE_LIST: (state, data) => {
		state.fahuoTemplateList = data
	},
	// 修改选择的发货单id
	SET_SELECT_FAHUO_ID: (state, data) => {
		state.selectFahuoId = data
	},
	// 修改默认的发货单模板
	SET_DEF_FAHUO_TEMPLATE: (state, data) => {
		state.defFahuoTemplate = data
	},
	// 修改发货单发货人列表
	SET_FAHUO_CONSIGNER_LIST: (state, data) => {
		state.fahuoConsignerList = data
	},
	// 修改快递单发货人列表
	SET_EXPRESS_CONSIGNER_LIST: (state, data) => {
		state.expressConsignerList = data
	},
	// 修改列配置
	SET_TABLE_HEADER: (state, data) => {
		state.tableHeader = data
	},
	// 修改列配置
	SET_TABLE_HEADER2: (state, data) => {
		state.tableHeader2 = data
	},
}

const actions = {
	// 获取快递列表
	get_express_list({ commit, state, dispatch }) {
		return new Promise((resolve, reject) => {
			getExpressList().then(res => {
				commit('SET_EXPRESS_LIST', res.data)
				resolve()
			}).catch(err => {
				reject(err)
			})
		})
	},
	// 获取快递模板
	get_express_template({ commit, state, dispatch }) {
		return new Promise((resolve, reject) => {
			getTemplate({ type: 1 }).then(res => {
				let arr=[]
				for(let i in res.data){
					arr.push(res.data[i])
				}
				commit('SET_EXPRESS_TEMPLATE_LIST',arr)
				resolve()
			}).catch(err => {
				reject(err)
			})
		})
	},
	// 添加快递模板
	add_express_template({ commit, state, dispatch }) {
		return new Promise((resolve, reject) => {
			addTemplate({ express_id: state.addExpressId }).then(res => {
				dispatch('get_express_template');
				resolve()
			}).catch(err => {
				reject(err)
			})
		})
	},
	// 获取发货单模板
	get_fahuo_template({ commit, state, dispatch }) {
		return new Promise((resolve, reject) => {
			getTemplate({ type: 2 }).then(res => {
				commit('SET_FAHUO_TEMPLATE_LIST', res.data)
				resolve()
			}).catch(err => {
				reject(err)
			})
		})
	},
	// 设置默认发货单模板
	set_def_fahuo_template({ commit, state, dispatch }) {
		return new Promise((resolve, reject) => {
			setDefFahuoTemplate({ temp_id: state.selectFahuoId }).then(res => {
				resolve()
			}).catch(err => {
				reject(err)
			})
		})
	},
	// 获取默认发货单模板
	get_def_fahuo_template({ commit, state, dispatch }) {
		return new Promise((resolve, reject) => {
			getDefFahuoTemplate().then(res => {
				commit('SET_DEF_FAHUO_TEMPLATE', res.data)
				resolve()
			}).catch(err => {
				reject(err)
			})
		})
	},
	// 获取发货单发货人列表
	get_fahuo_consigner_list({ commit, state, dispatch }) {
		return new Promise((resolve, reject) => {
			getFahuoConsignerList().then(res => {
				commit('SET_FAHUO_CONSIGNER_LIST', res.data)
				resolve()
			}).catch(err => {
				reject(err)
			})
		})
	},
	// 获取快递单发货人列表
	get_express_consigner_list({ commit, state, dispatch }) {
		return new Promise((resolve, reject) => {
			getExpressConsignerList().then(res => {
				commit('SET_EXPRESS_CONSIGNER_LIST', res.data)
				resolve()
			}).catch(err => {
				reject(err)
			})
		})
	},
	// 修改订单地址
	update_order_address({ }, params) {
		return new Promise((resolve, reject) => {
			updateOrderAddress(params).then(res => {
				resolve(res);
			}).catch(err => {
				reject(err);
			})
		})
	},

	// 获取电子面单信息
	GetExpressOrderInfo({commit},params){
		return new Promise((resolve, reject) => {
			getExpressOrderInfo(params).then(res => {
				resolve(res);
			}).catch(err => {
				reject(err);
			})
		})
	},

	// 修改列表表头配置
	setFieldData({commit},params){
		return new Promise((resolve, reject) => {
			setFieldData(params).then(res => {
				resolve(res);
			}).catch(err => {
				reject(err);
			})
		})
	},

	// 获取列表表头配置
	getFieldData({commit},params){
	return new Promise((resolve, reject) => {
		getFieldData(params).then(res => {
			resolve(res);
			commit('SET_TABLE_HEADER', JSON.parse(res.data))
			commit('SET_TABLE_HEADER2', JSON.parse(res.data))
		}).catch(err => {
			reject(err);
		})
	})
},

}

export default {
	namespaced: true,
	state,
	mutations,
	actions
}