import request from '../plugins/axios.js'

// 查询接口

// 获取查询结果
export function getSearchResult( params={} ) {
  params["NO_SHOW_LOADING"] = true
  return request(
    'post',
    '/PrintSearch/PrintSearchNew',
    params
  )
}